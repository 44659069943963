<template>
  <div class="container">
    <h1 v-if="pageId!==274">{{content.page.title}}</h1>
    <img class="loxon-logo" v-else src="@/assets/img/loxon-logo.png" alt="Loxon logó"/>
    <div v-html="content.page.mainContent">
    </div>
  </div>
  <section class="participants">
    <div class="container">
      <h3>Résztvevők</h3>
      <Participants :initData="initData"></Participants>
    </div>
  </section>
</template>

<script>

import Participants from "@/components/Participants.vue";

export default{
  name:'SubPage',
  components: {Participants},
  props:['initData','contents','pageId'],
  data(){

    return{}
  },
  computed:{
    language(){
      return this.initData.init.language
    },
    content(){
      let contents = this.contents[this.pageId]

      this.initData.init.head.pageId = this.pageId
      this.initData.init.head.title = contents.head.title
      this.initData.init.head.keywords = contents.head.keywords
      this.initData.init.head.description = contents.head.description

      return contents
    }
  }
}
</script>
<style scoped>
.container{
  padding: 4rem 0 4.5rem 0;
}
.container h1{
  color: var(--loxon-blue);
  font-family: 'TitilliumWeb-Bold',sans-serif;
  font-size: 2.8rem;
  line-height: 3.2rem;
  letter-spacing: 1px;
}
.container .loxon-logo{
  margin-bottom: 2.75rem;
}
.container :deep(h2){
  font-family: 'TitilliumWeb-SemiBold',sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  margin-top: 2.75rem;
}
.container :deep(h2:first-child){
  margin-bottom: 2.75rem;
  margin-top: 0;
}
.container :deep(h3){
  font-family: 'TitilliumWeb-SemiBold',sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  margin-top: 2.75rem;
}
.container :deep(h3:first-child){
  margin-top: 0;
}
.container :deep(a){
  color: var(--loxon-blue);
}
.participants{
  background-color: var(--loxon-orange);
  padding: 2rem 0;
}
.participants .container{
  padding: 0;
}
.participants h3{
  color: var(--white);
  margin-bottom: 2.75rem;
}
.container:deep(.responsive-youtube){
  overflow:hidden; padding-bottom:56.25%; position:relative; height:0;
}
.container:deep(.responsive-youtube iframe){
  left:0; top:0; height:100%; width:100%; position:absolute;
}
@media (max-width: 1030px) {

}
@media (max-width: 768px){

}
@media screen and (max-width: 576px) {
  .container{
    padding: 0 1rem;
  }
}

</style>