<template>
  <div class="row" v-if="this.$parent.$options.name==='MainPage'">
      <div class="col-lg-6 col-12" v-for="participant in participants" :key="participant.key">
        <div class="team">
          <div class="left">{{participant.place}}</div>
          <div class="right">
            <div class="team-name">{{participant.teamName}}</div>
            <div class="university">{{participant.university}}</div>
          </div>
        </div>
      </div>
  </div>
  <div v-else class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>Csapatnév</th>
          <th></th>
          <th>Intézmény</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="participant in participants" :key="participant.key">
          <td><div>{{participant.place}}</div></td>
          <td>{{participant.teamName}}</td>
          <td>{{participant.teamType}}</td>
          <td>{{participant.university}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default{
  name:'Participants',
  props:['initData'],
  data(){
    return{

    }
  },  
  computed:{
    participants(){
        let participants=[]
        if(this.$parent.$options.name==='MainPage'){
           participants = this.initData.init.participants.slice(0,6);
           participants.map( (participant, index) => {
                const newPropsObj = {
                    'place':this.initData.init.participants.length - index
                };
                return Object.assign(participants[index], newPropsObj);
            })
        }else if(this.$parent.$options.name==='SubPage' && this.$route.path!=='/resztvevok'){
            participants = this.initData.init.participants.slice(0,6);
            participants.map( (participant, index) => {
              const newPropsObj = {
                'place':this.initData.init.participants.length - index
              };
              return Object.assign(participants[index], newPropsObj);
            })
        }else{
          participants = this.initData.init.participants.sort((a,b)=> (a.teamName > b.teamName ? 1 : -1))
          participants.map( (participant, index) => {
            const newPropsObj = {
              'place':index + 1
            };
            return Object.assign(participants[index], newPropsObj);
          })
        }
        return participants
    }
  }
}
</script>

<style scoped>
.col-lg-6{
  display: inline-flex;
}
.team{
  width: 100%;
  height: 90px;
  border-radius: 80px;
  background-color: var(--white);
  display: flex;
  margin-bottom: 1.5rem;
}
.left{
  width: 90px;
  background-color: #EFEFEF;
  border-radius: 80px;
  color: #999999;
  font-family: 'TitilliumWeb-Light', sans-serif;
  font-size: 2.8rem;
  text-align: center;
  line-height: 90px;
  margin-right: 1.25rem;
}
.right{
  padding:10px 0 0 0;
}
.team-name{
  font-family: 'TitilliumWeb-SemiBold', sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  padding:0 0 3px 0;}
.university{
  line-height:1.3rem;
  height: 26px;
  font-family: 'TitilliumWeb-Light', sans-serif;
}
.table{
  color: #fff;
}
.table thead{
  font-family: 'TitilliumWeb-Light', sans-serif;
}
.table tbody{
  font-family: 'TitilliumWeb-SemiBold', sans-serif;
}
.table tbody td{
  vertical-align: middle;
}
.table td:nth-child(1) div{
  width: 90px;
  background-color: #EFEFEF;
  border-radius: 80px;
  color: #999999;
  font-family: 'TitilliumWeb-Light', sans-serif;
  font-size: 2.8rem;
  text-align: center;
  line-height: 90px;
}
.table td:nth-child(2){
  font-size: 1.8rem;
  line-height: 2rem;
  padding: 3rem 0;
}
</style>